import React, { useEffect } from 'react';
import './Home.css';
import { Helmet } from 'react-helmet';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Carousel, Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Digi1 from './assets/Digi1.webp'; 
import logo from './assets/logo.png';
import GA4React from 'ga-4-react'; // Import GA4 React package

const ga4react = new GA4React('G-E4Q40YQZ3X'); // Replace with your GA4 measurement ID

function Home() {
  useEffect(() => {
    ga4react.initialize()
      .then((ga) => {
        console.log("GA initialized");
        ga.gtag('config', 'G-J2JSF5BFGG', {
          page_path: window.location.pathname
        });
      })
      .catch((err) => {
        console.log("GA initialization failed", err);
      });
  }, []);

  return (
    <>
      <div> 
        <Helmet>
          <meta name="description" content="Looking for a top-notch digital service company to elevate your online presence? Look no further than Sparkdenz! Our team of tech-savvy experts is dedicated to providing cutting-edge digital solutions that will take your business to the next level." />
          <meta name="keywords" content="Ultimate Brand Building Company in 2024, Keyword2" />
        </Helmet>
        <h1>Ultimate Brand Building Company in 2024</h1>
      </div>
      
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <img className="dd" src={logo} alt="logo" />
          <Navbar.Brand href="#home">Sparkdenz</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="top-seo-service-company-2025">SEO SERVICES</Nav.Link>
              <Nav.Link href="best-sem-services-company-2025">SEM SERVICES</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        {/* Carousel Section */}
        <img className="lp" src={Digi1} alt="sp" />
        
        {/* Cards Section */}
        <Container className="my-5">
          <h1><b>OUR SERVICES</b></h1>
          <Row>
            <Col md={3}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>SEO</Card.Title>
                  <Card.Text>
                    Discover top-notch <b>SEO</b> solutions tailored to meet your needs.
                  </Card.Text>
                  <a href="top-seo-service-company-2025" className="btn btn-primary">Learn More</a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>SEM</Card.Title>
                  <Card.Text>
                    Unlock premium <b>SEM</b> solutions customized for your needs.
                  </Card.Text>
                  <a href="best-sem-services-company-2025" className="btn btn-primary">Learn More</a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>SMO</Card.Title>
                  <Card.Text>
                    Elevate your experience with customized <b>SMO</b> solutions.
                  </Card.Text>
                  <a href="SmoPage" className="btn btn-primary">Learn More</a>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>SMM</Card.Title>
                  <Card.Text>
                    Boost your success with our tailored <b>SMM</b> solutions.
                  </Card.Text>
                  <a href="SmmPage" className="btn btn-primary">Learn More</a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Footer Section */}
      <footer className="bg-dark text-white py-4">
        <Container>
          <Row>
            <Col md={4}>
              <h5>About Us</h5>
              <ul>
                <li><a href="#" className="text-white me-2">SEO</a></li>
                <li><a href="#" className="text-white me-2">SEM</a></li>
                <li><a href="#" className="text-white me-2">SMO</a></li>
                <li><a href="#" className="text-white me-2">SMM</a></li>
              </ul>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Email: sivaprasadm203@gmail.com</p>
              <p>Phone: +123 456 7890</p>
            </Col>
            <Col md={4}>
              <h5>Follow Us</h5>
              <ul>
                <li><a href="#" className="text-white me-2">Facebook</a></li>
                <li><a href="#" className="text-white me-2">Instagram</a></li>
                <li><a href="#" className="text-white me-2">Twitter</a></li>
                <li><a href="#" className="text-white me-2">WhatsApp</a></li>
              </ul>
            </Col>
          </Row>
          <Row className="mt-8">
            <Col className="text-center">
              <p>&copy; 2024 Your Company Name. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
export default Home