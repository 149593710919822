import React from 'react';
import { BrowserRouter,Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import SeoPage from './SeoPage.js';
import SemPage from './SemPage.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Home />} />
    
        <Route path="/top-seo-service-company-2025" element={<SeoPage />} />
        <Route path="/best-sem-services-company-2025" element={<SemPage />} />
  
      </Routes>
    
    </BrowserRouter>
  );
}

export default App;