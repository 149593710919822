import React, { useState } from 'react';
import './SemPage.css';
import { Helmet } from 'react-helmet';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Row, Col, Button, Form } from 'react-bootstrap';

const SemPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you can add functionality to send the form data to your server or API.
    alert('Thank you for contacting us!');
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <>
    <div>
      <Helmet>
        <title>Leading SEO Services Company in 2025 - SparkDenz

        </title>
        {/* You can also add other meta tags here */}
        <meta name="description" content="Maximize your online visibility with expert SEM services. SparkDenz offers customized ad campaigns in Madurai to drive targeted traffic and boost conversions in 2024
" />
        <meta name="keywords" content="Leading SEO Services in Dindigul for 2024 - SparkDenz
" />
      </Helmet>
      <h1>SEARCH ENGINE MARKETING</h1>
    </div>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          {/* <Nav.Link href="home">Home</Nav.Link> */}
          <Nav.Link href="top-seo-service-company-2025">SEO SERVICES</Nav.Link>
          <Nav.Link href="best-sem-services-company-2025">SEM SERVICES</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar><div className="seo-page">
    {/* <nav className="navbar">
          <h1>Our Services</h1>
          <ul className="nav-links">
            <li><a href="top-seo-service-company-2025">SEO</a></li>
            <li><a href="best-sem-services-company-2025">SEM</a></li>
          </ul>
        </nav> */}
    <Container>
      <header className="my-5">
        <h1>Drive Results with Expert Digital Marketing Services</h1>
        <p>Boost Your Online Visibility and Maximize ROI</p>
      </header>

      <section className="my-5">
        <h2>Why Choose Our Services?</h2>
        <Row>
          <Col md={4}>
            <h3>Custom Strategies</h3>
            <p>We create marketing plans tailored to your business goals and target audience.</p>
          </Col>
          <Col md={4}>
            <h3>Experienced Professionals</h3>
            <p>Our team of digital marketing experts delivers measurable results every time.</p>
          </Col>
          <Col md={4}>
            <h3>Performance-Driven Approach</h3>
            <p>We optimize campaigns with data and analytics to ensure higher returns.</p>
          </Col>
        </Row>
      </section>

      <section className="my-5">
        <h2>Our Core Services</h2>
        <Row>
          <Col md={6}>
            <h3>Search Engine Marketing (SEM)</h3>
            <p>Increase your visibility on search engines with targeted ads and optimized strategies.</p>
          </Col>
          <Col md={6}>
            <h3>Pay-Per-Click Advertising (PPC)</h3>
            <p>Get immediate traffic and results with our custom PPC campaigns.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h3>Social Media Advertising</h3>
            <p>Engage your audience with powerful social media ads on top platforms.</p>
          </Col>
          <Col md={6}>
            <h3>Content Marketing</h3>
            <p>Drive engagement and conversions with high-quality content.</p>
          </Col>
        </Row>
      </section>
</Container>

        <h1>Search Engine Marketing (SEM)</h1>
        <p>
          SEO is the process of improving the quality and quantity of website traffic to a website or a web page from search engines.
          SEO targets unpaid traffic rather than direct traffic or paid traffic.
        </p>

        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div></>
  );
};

export default SemPage;