import React, { useState } from 'react';
import './SeoPage.css';
import Nav from 'react-bootstrap/Nav';
import { Helmet } from 'react-helmet';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Row, Col, Button, Form } from 'react-bootstrap';

const SeoPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you can add functionality to send the form data to your server or API.
    alert('Thank you for contacting us!');
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <>
     <div>
      <Helmet>
        <title>Top Global SEO Services in Madurai for 2024 - SparkDenz
        </title>
        {/* You can also add other meta tags here */}
        <meta name="description" content="
Achieve higher rankings with professional SEO services in Madurai. SparkDenz delivers customized solutions to boost your online visibility in 2024.

" />
        <meta name="keywords" content="Top Global SEO Services in Madurai for 2024 - SparkDenz" />
      </Helmet>
      <h1>SEARCH ENGINE OPTIMIZATION</h1>
    </div>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="home">Home</Nav.Link> */}
            <Nav.Link href="top-seo-service-company-2025">SEO SERVICES</Nav.Link>
            <Nav.Link href="best-sem-services-company-2025">SEM SERVICES</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar><div className="seo-page">
    {/* <nav className="navbar">
          <h1>Our Services</h1>
          <ul className="nav-links">
            <li><a href="top-seo-service-company-2025">SEO</a></li>
            <li><a href="best-sem-services-company-2025">SEM</a></li>
          </ul>
        </nav> */}
    <Container>
      <header className="my-5">
        <h1>Expert Digital Marketing Services</h1>
        <p>Boost Your Online Presence with Tailored Marketing Solutions</p>
      </header>

      <section className="my-5">
        <h2>Why Choose Our Services?</h2>
        <Row>
          <Col md={4}>
            <h3>Tailored Strategies</h3>
            <p>We develop personalized marketing plans based on your business goals and target audience.</p>
          </Col>
          <Col md={4}>
            <h3>Expert Team</h3>
            <p>Our team consists of seasoned professionals with a proven track record of success in digital marketing.</p>
          </Col>
          <Col md={4}>
            <h3>Data-Driven Results</h3>
            <p>We use advanced analytics to track performance and optimize campaigns for maximum ROI.</p>
          </Col>
        </Row>
      </section>

      <section className="my-5">
        <h2>Our Services</h2>
        <Row>
          <Col md={6}>
            <h3>Search Engine Optimization (SEO)</h3>
            <p>Improve your website's visibility on search engines with our comprehensive SEO services.</p>
          </Col>
          <Col md={6}>
            <h3>Pay-Per-Click Advertising (PPC)</h3>
            <p>Drive immediate traffic to your website with targeted PPC campaigns.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h3>Social Media Management</h3>
            <p>Engage with your audience and build your brand across all major social platforms.</p>
          </Col>
          <Col md={6}>
            <h3>Content Marketing</h3>
            <p>Create compelling content that resonates with your audience and drives conversions.</p>
          </Col>
        </Row>
      </section>
</Container>
      
        <h1>Search Engine Optimization (SEO)</h1>
        <p>
          SEO is the process of improving the quality and quantity of website traffic to a website or a web page from search engines.
          SEO targets unpaid traffic rather than direct traffic or paid traffic.
        </p>

        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div></>
  );
};

export default SeoPage;